<template>
    <div class="py-16 px-2">
        <h1 class="text-red text-lg mb-6">
            Edit Ticket
        </h1>
        <div class="" v-if="item">
            <SlideoutTabs default_width="half" :model="{id:item.id,type:'Ticket'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
            </SlideoutTabs>
            <!-- <Attachments model="Ticket" :item="item" v-model="item.attachments"></Attachments> -->
            <FormTemplate @response="formResponse" button="Save" method="post" action="/ticket" :formdata="formData">
                <TextInput type="text" label="Title" v-model="item.title" :required="false" ></TextInput>
                <SelectInput label="Status" v-model="item.status" :options="[{label:'Open',value:'Open'},{label:'Closed',value:'Closed'},{label: 'Archived',value:'Archived'}]" :required="false" ></SelectInput>
                <SearchSelectInput @item:created="TagCreated" @item:removed="tagRemoved" @item:selected="tagSelected" :can_create="true" :params="tagSearchParams" :multicheck="true" :multiple="true" label="Tags" v-model="item.tags" :optiondisplay="tagDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="userRemoved" @item:selected="userSelected" :params="userSearchParams" :multicheck="false" :multiple="true" label="Assignees" v-model="item.users" :optiondisplay="userDisplay"></SearchSelectInput>
                <DatePicker label="Due Date" v-model="item.due" :time="true"></DatePicker>
                <Wysiwyg class="p-2 my-2" label="Description" v-model="item.description"></Wysiwyg>
                <p>
                    Created On  {{getDate(item.created_at)}}
                </p>
                <p>
                    Last Updated On  {{getDate(item.updated_at)}}
                </p>
                <p>
                    Created By  {{item.created_by.email}}
                </p>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import Axios from 'axios'
import TicketObjects from '../../../components/dashboard/TicketObjects'
import Comments from '../../../components/dashboard/Comments'
import Attachments from '../../../components/dashboard/Attachments'

export default {
    name: 'Ticket',
    mixins: [View],
    data: () => ({
        back: '/tickets',
        parameter: 'ticket_id',
        action: '/ticket/',
        tagSearchParams: {
            'model': 'Tag',
            'fields': ['id','name'],
            'action': '/search/',
        },
        userSearchParams: {
            'model': 'User',
            'fields': ['id','name','email'],
            'action': '/search/',
        },
        tab_content: {
            'attachments': {
                label: 'Attachments',
                icon: '/assets/attachments.png',
                component: Attachments,
                top: '200px',
            },
            'objects': {
                label: 'Related Items',
                icon: '/assets/related-objects.png',
                component: TicketObjects,
                top: '300px',
            },
            'comments': {
                label: 'Comments',
                icon: '/assets/comments.png',
                component: Comments,
                top: '400px',
            },
        }
    }),
    computed: {
        formData() {
            return {
                'ticket':this.item,
            }
        },
    },
    props: [],
    methods: {
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        tagDisplay(item) {
            return item.name
        },
        tagSelected(evt) {
            let index = this.item.tags.findIndex((tag)=>{return tag.id == evt.item.id})
            if(index < 0) this.item.tags.push(evt.item);
        },
        tagRemoved(evt) {
            this.item.tags.splice(evt.index,1);
        },
        TagCreated(evt) {
            let index = this.item.tags.findIndex((tag)=>{return tag.name == evt.input})
            if(index < 0) {
                this.item.tags.push({
                    name: evt.input,
                    id: evt.uid,
                    maybeCreateMe: true,
                });
            }
        },
        userDisplay(item) {
            return item.email
        },
        userSelected(evt) {
            let index = this.item.users.findIndex((user)=>{return user.id == evt.item.id})
            if(index < 0) this.item.users.push(evt.item);
        },
        userRemoved(evt) {
            this.item.users.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
        Axios.get('/ticket/seen/'+this.$route.params.ticket_id).catch(err=>{console.log(err)})
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
