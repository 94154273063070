<template>
<div id="tickets" class="w-full">
    <router-view/>
</div>
</template>

<script>

export default {
    name: 'Tickets',
    data: () => ({
    }),
    computed: {
    },
    props: [],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
