<template>
<div class="relative">
    <div class="flex flex-col">
        <TextInput type="text" label="Search" v-model="search" :required="false" icon_before="/assets/search.png"></TextInput>
    </div>
    <div v-if="search_results" class="absolute w-full bg-white border p-12" style="height:max-content">
        <div class="flex flex-col">
            <ButtonTemplate v-if="checked_search_objects.length" :on_click="AddSearchObjects" class="my-6" type="button">
                Add Items
            </ButtonTemplate>
        </div>
        <div class="flex flex-col">
            <div v-for="(group_objects,group_name) in search_results" :key="group_name" class="flex-auto p-4">
                <h2 class="capitalize text-md text-red">
                    {{group_name}}
                </h2>
                <div class="flex-col  flex w-full">
                    <component :multiple="true" @item:selected="SearchObjectSelected($event,group_name)" @item:removed="SearchObjectRemoved($event,group_name)" v-bind:is="group_name+'List'" :objects="group_objects" />
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <div v-if="checked_objects.length" class="flex">
            <ButtonTemplate :on_click="RemoveCheckItems" class="my-6" type="button">
                Remove Items
            </ButtonTemplate>
        </div>
        <div v-for="(group_objects,group_name) in objects" :key="group_name" class="flex-auto p-4">
            <h2 class="capitalize text-md text-red">
                {{group_name}}
            </h2>
            <div class="flex-col flex w-full">
                <component :multiple="true" @item:selected="ObjectSelected($event,group_name)" @item:removed="ObjectRemoved($event,group_name)" v-bind:is="group_name+'List'" :objects="group_objects" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'TicketObjects',
    data: () => ({
        objects: [],
        checked_objects: [],
        search_val: '',
        search_timeout: false,
        searching: false,
        search_results: false,
        checked_search_objects: [],
    }),
    computed: {
        search: {
            set(val){
                let that = this
                this.search_val = val
                if(this.search_timeout) clearTimeout(this.search_timeout)
                this.search_timeout = setTimeout(()=>{
                    that.search_results = false
                    that.FindObjects()
                },1000)
            },
            get() {
                return this.search_val
            },
        },
    },
    props: ['model'],
    methods: {
        AddSearchObjects() {
            this.$requests.post(`/ticket/${this.model.id}/related-objects`,{
                id: this.model.id,
                objects: this.checked_search_objects,
            },
            {
                success: (re) => {
                    this.search_results = false
                    this.checked_search_objects = []
                    this.search_results = false
                    this.objects = re.data.objects
                    this.search_val = ''
                    return true
                },
            })
        },
        SearchObjectSelected(item,type) {
            let index = this.checked_search_objects.findIndex((object)=>{return object.id == item.id})
            if(index < 0) this.checked_search_objects.push({model:type,id:item.id})
        },
        SearchObjectRemoved(item,type){
            let index = this.checked_search_objects.findIndex((object)=>{return object.id == item.id})
            if(index > -1) this.checked_search_objects.splice(index,1)
        },
        ObjectSelected(item,type) {
            this.checked_objects.push({model:type,id:item.id})
        },
        ObjectRemoved(item,type){
            let index = this.checked_objects.findIndex((object)=>{return object.id == item.id})
            if(index > -1) this.checked_objects.splice(index,1)
        },
        RemoveCheckItems() {
            this.$requests.post(`/ticket/${this.model.id}/related-objects/remove`,{
                objects: this.checked_objects,
            },
            {
                success: (re) => {
                    this.checked_objects = []
                    this.objects = re.data.items
                },
            })
        },
        FindObjects() {
            if(!this.search_val || this.search_val.trim() == '') return false
            this.$requests.post('/search-objects',{
                search_string: this.search_val,
                not: ['Comments','Notes'],
            },
            {
                success: (re) => {
                    this.search_results = re.data.objects
                    return true
                },
            })
        },
        Capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        GetObjects() {
            let that = this
            this.$requests.get(`/ticket/${this.model.id}/related-objects`,
            {
                success: (re) => {
                    that.objects = re.data.items
                    return true
                },
            })
        },
    },
    watch: {
    },
    created() {
        if(this.model && this.model.id) this.GetObjects()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
