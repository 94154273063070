<template>
<div class="container py-16 px-2">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Tickets
        </h1>
        <LinkTemplate :to="{path:'/tickets/create'}" class="my-6 ml-6">
            Add New Ticket
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length"  class="">
        <TableTemplate v-if="loading == false" :candelete="false" canview="/tickets/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false"  @item:deleted="ItemDeleted" :candelete="true" canview="/tickets/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
import Swal from 'sweetalert2'
import Axios from 'axios'
export default {
    name: 'TicketsList',
    mixins: [List],
    data: () => ({
        search: '',
        limit: 10,
        page: 0,
        action: '/tickets',
        searchParams: {
            'model': 'Ticket',
            'fields': ['id','title','description'],
            'strict' : false,
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'title',
                label:'Title',
            },
            {
                id:'status',
                label:'Status',
            },
            {
                id:'seen_by',
                label:'Seen By',
                custom: 'SeenBy'
            },
            {
                id:'users',
                label:'Assignees',
                custom: 'displayUsers',
            },
            {
                id:'created_by',
                label:'Created By',
                custom: 'displayCreatedBy',
            }
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        SeenBy(item) {
            let emails = ''
            let first = true
            for(let i in item.seen_by) {
                if(!first){
                    emails += ', '
                }else{
                    first = false
                }
                emails += item.seen_by[i]
            }
            return emails
        },
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        MaybeArchiveTicket(item) {
            let that = this
            Swal.fire({
                title: 'Are you sure you want to archive this ticket?',
                text: "",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    that.ArchiveTicket(item)
                }
            })
        },
        ArchiveTicket(item) {
            let that = this
            Axios.post('/ticket/archive',{ticket:item}).then(re=>{
                that.RemoveItem(re.data.item)
                that.$notify({
                    title: 'Archived',
                    text: '',
                    type: 'success',
                });
            }).catch(err=>{
                console.log(err)
            })
        },
        displayUsers(item) {
            let users = ''
            let first = true
            for(let i in item.users) {
                if(!first){
                    users += ', '
                }else{
                    first = false
                }
                users += item.users[i].email
            }
            return users
        },
        displayCreatedBy(item) {
            return item.created_by.email
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
